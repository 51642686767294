import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { CheckCircle2 } from 'lucide-react';

const translations = {
  en: {
    welcomeBack: "Welcome",
    discoverPalette: "Discover your perfect color palette and get your color analysis now",
    termsAgreement: "By signing in, you agree to our",
    termsOfService: "Terms of service",
    and: "and",
    privacyPolicy: "Privacy policy",
    whyChoose: "Why Choose Palette Hunt?",
    features: [
      "Color analysis in less than 1h",
      "Personalized style recommendations",
      "Try on recommended colors",
      "Interactive color playground",
    ],
  },
  es: {
    welcomeBack: "Bienvenido",
    discoverPalette: "Descubre tu paleta de colores perfecta y obtén tu análisis de color ahora",
    termsAgreement: "Al iniciar sesión, aceptas nuestros",
    termsOfService: "Términos de servicio",
    and: "y",
    privacyPolicy: "Política de privacidad",
    whyChoose: "¿Por qué elegir Palette Hunt?",
    features: [
      "Análisis de color en menos de 1h",
      "Recomendaciones de estilo personalizadas",
      "Prueba los colores recomendados",
      "Playground de colores interactivo",
    ],
  },
  pt: {
    welcomeBack: "Bem-vindo",
    discoverPalette: "Descubra sua paleta de cores perfeita e obtenha seu análise de cor agora",
    termsAgreement: "Ao fazer login, você concorda com nossos",
    termsOfService: "Termos de serviço",
    and: "e",
    privacyPolicy: "Política de privacidade",
    whyChoose: "Por que escolher o Palette Hunt?",
    features: [
      "Análise de cores em menos de 1h",
      "Recomendações de estilo personalizadas",
      "Experimentação das cores recomendadas",
      "Playground de cores interativo",
    ],
  },
};

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const language = location.pathname.startsWith('/es/') ? 'es' : 
                   location.pathname.startsWith('/pt/') ? 'pt' : 'en';
  const t = translations[language];
  const isNativeMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    if (isNativeMobile) {
      GoogleAuth.initialize({
        clientId: process.env.REACT_APP_IOS_CLIENT_ID,
        scopes: ['profile', 'email'],
        grantOfflineAccess: true,
      });
    }
  }, [isNativeMobile]);

  const handleGoogleSuccess = async (credentialResponse) => {
    setIsLoading(true);
    setError(null);
    try {
      let idToken;
      if (isNativeMobile) {
        idToken = credentialResponse.authentication.idToken;
      } else {
        idToken = credentialResponse.credential;
      }
      const verifyResponse = await axios.post(`${process.env.REACT_APP_API_GATEWAY_ENDPOINT}/verify-token`, 
        { idToken },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (verifyResponse.data.valid) {
        localStorage.setItem('token', verifyResponse.data.token);
        if (verifyResponse.data.hasPaid) {
          navigate(verifyResponse.data.nextPage);
        } else {
          localStorage.setItem('googleCredential', idToken);
          navigate(language === 'en' ? '/payment' : `/${language}/payment`);
        }
      } else {
        throw new Error('Invalid token');
      }
    } catch (error) {
      console.error('Google login error:', error);
      setError('An error occurred during Google login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleError = (error) => {
    // Handle Google login error
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <img src="/logo.png" alt="Loading" className="w-24 h-24 animate-pulse" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-white flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-2xl shadow-lg overflow-hidden">
        <div className="p-8">
          <Link to={language === 'en' ? '/' : `/${language}`} className="flex items-center justify-center mb-8">
            <img src="/logo.png" alt="Palette Hunt Logo" className="h-16 w-auto" />
          </Link>
          
          <h2 className="text-4xl font-bold text-gray-900 text-center mb-4">{t.welcomeBack}</h2>
          <p className="text-xl text-gray-600 text-center mb-8 max-w-xs mx-auto">{t.discoverPalette}</p>
          
          <div className="mb-8 flex flex-col items-center">
            {isLoading ? (
              <ClipLoader color="#000000" loading={isLoading} size={50} />
            ) : isNativeMobile ? (
              <>
                <button
                  onClick={() => GoogleAuth.signIn().then(handleGoogleSuccess).catch(handleGoogleError)}
                  className="w-full flex items-center justify-center px-6 py-3 border border-gray-300 rounded-full text-gray-700 bg-white hover:bg-gray-50 transition duration-300 mb-4"
                >
                  <img src="/google.png" alt="Google" className="w-5 h-5 mr-3" />
                  Sign in with Google
                </button>
              </>
            ) : (
              <div className="w-full flex justify-center">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleError}
                  useOneTap={false}
                  cookiePolicy={'single_host_origin'}
                  size="large"
                  text="continue_with"
                  shape="pill"
                  theme="outline"
                  width="300px"
                />
              </div>
            )}
          </div>

          {error && (
            <p className="text-red-600 text-sm text-center mb-4">{error}</p>
          )}

          {!isNativeMobile && (
            <div className="space-y-4">
              <h3 className="text-xl font-bold text-gray-900 text-center mb-6">{t.whyChoose}</h3>
              {t.features.map((feature, index) => (
                <div key={index} className="flex items-center">
                  <CheckCircle2 className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                  <span className="text-gray-600">{feature}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
