import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CheckCircle2, Globe, ChevronDown } from 'lucide-react';
import Footer from './Footer';
import locations from '../data/locations';
import { FaMapMarkerAlt, FaPalette, FaDollarSign, FaBuilding } from 'react-icons/fa';
import { LogoCarousel } from './LandingPage';  // Add this import

const ImageWithFallback = ({ src, alt, className }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = '/placeholder.png';
      }}
    />
  );
};

const translations = {
  en: {
    title: "Color Analysis in",
    description: "Discover your perfect color palette with Palette Hunt's AI-powered analysis in",
    getStarted: "Discover my colors",
    contact: "Contact",
    signIn: "Sign in",
    imagesCreated: "+15,000 images created",
    heroTitle: "Color Analysis",
    heroSubtitle: "Discover your perfect color palette in {location} (or anywhere) with our AI-powered analysis. Our advanced technology creates personalized AI photos of you in your most flattering colors, all from the comfort of your home.",
    uploadPhotos: "Upload some photos and find your perfect palette now",
    inPersonAnalysis: "In-Person Color Analysis",
    aiPoweredAnalysis: "AI-Powered Color Analysis",
    colorAnalysisIn: "Color Analysis in {location}",
    locationOverview: "Location Overview",
    colorAnalysisServices: "Color Analysis Services",
    colorAnalysisServicesDesc: "{location} offers a wide range of color analysis services, from traditional in-person consultations to cutting-edge AI-powered solutions like Palette Hunt.",
    pricingInformation: "Pricing Information",
    averagePriceRange: "Average price range for color analysis in {location}:",
    popularColorAnalysisServices: "Popular Color Analysis Services",
    experiencePaletteHunt: "Experience Palette Hunt's AI-powered color analysis, available anywhere in {location}.",
    tryPaletteHuntNow: "Discover my colors now",
    lovedByUsers: "Loved by our users",
    testimonialTitle: "What our users say",
    testimonialSubtitle: "Discover how Palette Hunt has helped others find their perfect colors.",
    testimonial1: "I never thought yellow colors would suit me, but seeing myself in them has changed my mind completely.",
    testimonial2: "The AI-generated photos helped me visualize how different colors would actually look on me. It's amazing!",
    verifiedUser: "✓ Verified User",
    pricingTitle: "Choose Your Perfect Package",
    pricingSubtitle: "Find the perfect color analysis package for your needs",
    basic: "Basic",
    basicPrice: "$19",
    basicDescription: "Perfect for those just starting their color journey",
    pro: "Pro",
    proPrice: "$39",
    proDescription: "For those who want to dive deeper into their colors",
    custom: "Custom",
    customPrice: "Contact us",
    customDescription: "For businesses and special requirements",
    month: "/analysis",
    features: "Features",
    basicFeatures: [
      "Color Analysis Report",
      "Seasonal Color Palette",
      "Basic Color Recommendations",
      "5 AI Photos"
    ],
    proFeatures: [
      "Everything in Basic",
      "Detailed Color Analysis",
      "Extended Color Palette",
      "20 AI Photos",
      "Style Recommendations",
      "Makeup Color Guide"
    ],
    customFeatures: [
      "Everything in Pro",
      "Custom Requirements",
      "Priority Support",
      "Unlimited AI Photos",
      "API Access",
      "White Label Option"
    ],
    getFeatured: "Get Featured",
    mostPopular: "Most Popular",
    faqTitle: "Frequently Asked Questions",
    faqSubtitle: "Everything you need to know about color analysis",
    faqs: [
      {
        question: "How does Palette Hunt work?",
        answer: "We use the most advanced AI vision algorithms to analyze your photos and generate personalized color palettes that complement your unique features and style preferences."
      },
      {
        question: "How long does it take to get results?",
        answer: <>You will typically receive your results within 1h. If the results are taking more than 2h, please send us an email at <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "Will the quality of the results be good?",
        answer: "Yes, we can assure you that the quality of the results will be the best you can get in the market. But you need to provide us with good quality photos to maximize the quality of the results."
      },
      {
        question: "Do I need to upload many pictures?",
        answer: "For optimal results, we recommend uploading 8-10 photos that showcase different angles, lighting conditions, and outfits. This will determine the quality of the results!"
      },
      {
        question: "Can I keep the generated images?",
        answer: "Absolutely! You own all the images generated by Palette Hunt. Both for the playground and for the color analysis."
      },
      {
        question: "Can I use Palette Hunt on mobile devices?",
        answer: "Yes, no problem! Palette Hunt works in either mobile or desktop. Feel free to use the device you are most comfortable with!"
      },
      {
        question: "How accurate are the color recommendations?",
        answer: "Our color recommendation process has been thoroughly tested to make sure our results are the best you can get. Our satisfaction rate is extremely high among our users."
      },
      {
        question: "Which type of photos will I get?",
        answer: "For each color palette you get, you will get 2 images of you in that color palette. One will have a white background, like in a studio, and one will have a background that matches the color theme."
      },
      {
        question: "What happens if I don't like the results?",
        answer: <>Quality is our top priority. At this time, we cannot offer full refunds as every color analysis means a signficant cost per user. We are sure though, we will find a solution for you. We are always available at <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "Will my photos be shared with anyone?",
        answer: "No. We just use the photos to train our AI that no one else has access to. Every uploaded picture you upload gets deleted after a week."
      }
    ],
    haveQuestion: "Have another question? Contact me on",
    playgroundTitle: "Your Style, Reinvented",
    playgroundDescription1: "Step into our virtual try-on studio where imagination knows no bounds. See yourself in as many looks and colors as you want.",
    playgroundDescription2: "Create images of yourself in styles you never thought you could wear.",
    playgroundAlt: "Palette Hunt Try-on Studio",
    carouselTitle: "Do You Want To Understand What Colors Suit You?",
    carouselDescription: "Our AI-powered color analysis provides detailed explanations and shows you transformed in your perfect colors using advanced AI-generated photos.",
    carouselFeature1Title: "Personalized Analysis",
    carouselFeature1Description: "Get a detailed color analysis based on your unique features.",
    carouselFeature2Title: "AI-Generated Photos",
    carouselFeature2Description: "See yourself transformed in your perfect colors.",
    carouselFeature3Title: "Expert Recommendations",
    carouselFeature3Description: "Receive personalized style advice and color combinations.",
    imageGridTitle: "See Yourself in Your Perfect Colors",
    imageGridDescription: "Don't just read about colors that might suit you - see them in action on your personalized AI model. Our comprehensive color analysis shows you exactly how your perfect palette looks on you. All it takes is some selfies.",
    aiPhotoLabel: "AI Photo",
    seasonTypes: {
      warmSpring: "Warm Spring 🌸",
      warmAutumn: "Warm Autumn 🍁",
      softSummer: "Soft Summer ☀️",
      deepAutumn: "Deep Autumn 🍂"
    },
    choosePlan: "Choose Your Color Analysis Plan",
    selectPlan: "Select a plan that fits your needs and start discovering your perfect color palette.",
    basicPlan: "Basic Plan",
    proPlan: "Pro Plan",
    enterprise: "Enterprise",
    custom: "Custom",
    oneOff: "One-off",
    getStarted: "Discover My Colors",
    getPro: "Get Pro",
    contactUs: "Contact Us",
    popular: "Popular",
    colorAnalysisTest: "Color analysis test",
    tailoredPalettes: "tailored color palettes",
    picsInColors: "pics of you in those colors",
    playgroundCredits: "playground credits",
    goldSilverTest: "Gold or Silver test",
    tailoredSolutions: "Tailored color analysis solutions",
    bulkPricing: "Bulk pricing options",
    customisedFeatures: "Customised features"
  },
  es: {
    title: "Colorimetría en",
    description: "Descubre tu paleta de colores perfecta con el análisis impulsado por IA de Palette Hunt en",
    getStarted: "Descubre mis colores",
    contact: "Contacto",
    signIn: "Iniciar sesión",
    imagesCreated: "+15,000 imágenes creadas",
    heroTitle: "Colorimetría",
    heroSubtitle: "Descubre tu paleta de colores perfecta en {location} (o en cualquier lugar) con nuestro análisis impulsado por IA. Nuestra tecnología avanzada crea fotos personalizadas con IA tuyas en tus colores más favorecedores, todo desde la comodidad de tu hogar.",
    uploadPhotos: "Sube algunas fotos y encuentra tu paleta perfecta ahora",
    inPersonAnalysis: "Colorimetría Presencial",
    aiPoweredAnalysis: "Colorimetría con IA",
    colorAnalysisIn: "Colorimetría en {location}",
    locationOverview: "Descripción General",
    colorAnalysisServices: "Servicios de Colorimetría",
    colorAnalysisServicesDesc: "{location} ofrece una amplia gama de servicios de colorimetría, desde consultas presenciales tradicionales hasta soluciones de vanguardia impulsadas por IA como Palette Hunt.",
    pricingInformation: "Información de Precios",
    averagePriceRange: "Rango de precios promedio para colorimetría en {location}:",
    popularColorAnalysisServices: "Servicios Populares de Colorimetría",
    experiencePaletteHunt: "Experimenta el análisis de color impulsado por IA de Palette Hunt, disponible en cualquier lugar de {location}.",
    tryPaletteHuntNow: "Descubre mis colores ahora",
    lovedByUsers: "Lo que dicen nuestros usuarios",
    testimonialTitle: "Lo que dicen nuestros usuarios",
    testimonialSubtitle: "Descubre cómo Palette Hunt ha ayudado a otros a encontrar sus colores perfectos.",
    testimonial1: "Nunca pensé que los colores amarillos me quedarían bien, pero verme en ellos ha cambiado mi opinión por completo.",
    testimonial2: "¡Las fotos generadas por IA me ayudaron a visualizar cómo diferentes colores se verían en mí. ¡Es increíble!",
    verifiedUser: "✓ Usuario Verificado",
    pricingTitle: "Elige Tu Paquete Perfecto",
    pricingSubtitle: "Encuentra el paquete de análisis de color perfecto para tus necesidades",
    basic: "Básico",
    basicPrice: "$19",
    basicDescription: "Perfecto para quienes comienzan su viaje del color",
    pro: "Pro",
    proPrice: "$39",
    proDescription: "Para quienes quieren profundizar en sus colores",
    custom: "Personalizado",
    customPrice: "Contáctanos",
    customDescription: "Para empresas y requisitos especiales",
    month: "/análisis",
    features: "Características",
    basicFeatures: [
      "Informe de Análisis de Color",
      "Paleta de Color Estacional",
      "Recomendaciones Básicas de Color",
      "5 Fotos IA"
    ],
    proFeatures: [
      "Todo lo del Básico",
      "Análisis de Color Detallado",
      "Paleta de Color Extendida",
      "20 Fotos IA",
      "Recomendaciones de Estilo",
      "Guía de Colores de Maquillaje"
    ],
    customFeatures: [
      "Todo lo del Pro",
      "Requisitos Personalizados",
      "Soporte Prioritario",
      "Fotos IA Ilimitadas",
      "Acceso API",
      "Opción de Marca Blanca"
    ],
    getFeatured: "Destacarse",
    mostPopular: "Más Popular",
    faqTitle: "Preguntas Frecuentes",
    faqSubtitle: "Todo lo que necesitas saber sobre el análisis de color",
    faqs: [
      {
        question: "¿Cómo funciona Palette Hunt?",
        answer: "Utilizamos los algoritmos de visión por IA más avanzados para analizar tus fotos y generar paletas de colores personalizadas que complementan tus características únicas y preferencias de estilo."
      },
      {
        question: "¿Cuánto tiempo tarda en dar resultados?",
        answer: <>Normalmente recibirás tus resultados en 1 hora. Si los resultados tardan más de 2 horas, envíanos un email a <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "¿La calidad de los resultados será buena?",
        answer: "Sí, podemos asegurarte que la calidad de los resultados será la mejor que puedes obtener en el mercado. Pero necesitas proporcionarnos fotos de buena calidad para maximizar la calidad de los resultados."
      },
      {
        question: "¿Necesito subir muchas fotos?",
        answer: "Para obtener resultados óptimos, recomendamos subir 8-10 fotos que muestren diferentes ángulos, condiciones de iluminación y atuendos. ¡Esto determinará la calidad de los resultados!"
      },
      {
        question: "¿Puedo conservar las imágenes generadas?",
        answer: "¡Absolutamente! Eres dueño de todas las imágenes generadas por Palette Hunt. Tanto para el probador virtual como para el análisis de color."
      },
      {
        question: "¿Puedo usar Palette Hunt en dispositivos móviles?",
        answer: "¡Sí, sin problema! Palette Hunt funciona tanto en móvil como en escritorio. ¡Siéntete libre de usar el dispositivo con el que te sientas más cómodo!"
      },
      {
        question: "¿Qué tan precisas son las recomendaciones de color?",
        answer: "Nuestro proceso de recomendación de color ha sido probado exhaustivamente para asegurarnos de que nuestros resultados sean los mejores que puedas obtener. Nuestra tasa de satisfacción es extremadamente alta entre nuestros usuarios."
      },
      {
        question: "¿Qué tipo de fotos recibiré?",
        answer: "Por cada paleta de colores que recibas, obtendrás 2 imágenes tuyas en esa paleta de colores. Una tendrá un fondo blanco, como en un estudio, y otra tendrá un fondo que coincida con el tema de color."
      },
      {
        question: "¿Qué pasa si no me gustan los resultados?",
        answer: <>La calidad es nuestra principal prioridad. En este momento, no podemos ofrecer reembolsos completos, ya que cada análisis de color significa un costo significativo por usuario. Sin embargo, estamos seguros de que encontraremos una solución para ti. Estamos siempre disponibles en <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "¿Mis fotos serán compartidas con alguén?", 
        answer: "No. Solo usamos las fotos para entrenar nuestra IA, a la cual nadie más tiene acceso. Cada foto que envías es eliminada después de una semana."
      }
    ],
    haveQuestion: "¿Tienes otra pregunta? Contáctame en",
    or: "o por",
    playgroundTitle: "Tu Estilo, Reinventado",
    playgroundDescription1: "Entra en nuestro estudio virtual de pruebas donde la imaginación no tiene límites. Mírate en tantos looks y colores como quieras.",
    playgroundDescription2: "Crea imágenes tuyas en estilos que nunca pensaste que podrías usar.",
    playgroundAlt: "Estudio de Pruebas de Palette Hunt",
    carouselTitle: "¿Quieres Entender Qué Colores Te Favorecen?",
    carouselDescription: "Nuestro análisis de color impulsado por IA proporciona explicaciones detalladas y te muestra transformado en tus colores perfectos usando fotos avanzadas generadas por IA.",
    carouselFeature1Title: "Análisis Personalizado",
    carouselFeature1Description: "Obtén un análisis de color detallado basado en tus características únicas.",
    carouselFeature2Title: "Fotos Generadas por IA",
    carouselFeature2Description: "Mírate transformado en tus colores perfectos.",
    carouselFeature3Title: "Recomendaciones Expertas",
    carouselFeature3Description: "Recibe consejos de estilo personalizados y combinaciones de colores.",
    imageGridTitle: "Mírate en Tus Colores Perfectos",
    imageGridDescription: "No solo leas sobre colores que podrían quedarte bien - vélos en acción en tu modelo personalizado de IA. Nuestro análisis de color completo te muestra exactamente cómo se ve tu paleta perfecta en ti. Todo lo que necesitas son algunas selfies.",
    aiPhotoLabel: "Foto IA",
    seasonTypes: {
      warmSpring: "Primavera Cálida 🌸",
      warmAutumn: "Otoño Cálido 🍁",
      softSummer: "Verano Suave ☀️",
      deepAutumn: "Otoño Profundo 🍂"
    },
    choosePlan: "Elige tu Plan",
    selectPlan: "Selecciona un plan que se adapte a tus necesidades y comienza a descubrir tu paleta de colores perfecta.",
    basicPlan: "Plan Básico",
    proPlan: "Plan Pro",
    enterprise: "Empresas",
    custom: "Custom",
    oneOff: "Único pago",
    getStarted: "Empezar",
    getPro: "Obtener Pro",
    contactUs: "Contáctanos",
    popular: "Popular",
    colorAnalysisTest: "Test de análisis de color",
    tailoredPalettes: "paletas de colores personalizadas",
    picsInColors: "fotos tuyas en esos colores",
    playgroundCredits: "créditos de probador virtual",
    goldSilverTest: "Test de oro o plata",
    tailoredSolutions: "Soluciones de análisis de color personalizadas",
    bulkPricing: "Opciones de precios por volumen",
    customisedFeatures: "Características personalizadas"
  },
  pt: {
    title: "Coloração Pessoal em",
    description: "Descubra sua paleta de cores perfeita com a análise alimentada por IA da Palette Hunt em",
    getStarted: "Descubra minhas cores",
    contact: "Contato",
    signIn: "Entrar",
    imagesCreated: "+15,000 imagens criadas",
    heroTitle: "Coloração Pessoal",
    heroSubtitle: "Descubra sua paleta de cores perfeita em {location} (ou em qualquer lugar) com nossa análise alimentada por IA. Nossa tecnologia avançada cria fotos personalizadas com IA suas nas suas cores mais favoráveis, tudo no conforto da sua casa.",
    uploadPhotos: "Envie algumas fotos e encontre sua paleta perfeita agora",
    inPersonAnalysis: "Coloração Pessoal Presencial",
    aiPoweredAnalysis: "Coloração Pessoal com IA",
    colorAnalysisIn: "Coloração Pessoal em {location}",
    locationOverview: "Visão Geral",
    colorAnalysisServices: "Serviços de Coloração Pessoal",
    colorAnalysisServicesDesc: "{location} oferece uma ampla gama de serviços de coloração pessoal, desde consultas presenciais tradicionais até soluções de ponta alimentadas por IA como a Palette Hunt.",
    pricingInformation: "Informações de Preços",
    averagePriceRange: "Faixa de preços média para coloração pessoal em {location}:",
    popularColorAnalysisServices: "Serviços Populares de Coloração Pessoal",
    experiencePaletteHunt: "Experimente a análise de cores alimentada por IA da Palette Hunt, disponível em qualquer lugar em {location}.",
    tryPaletteHuntNow: "Descubra minhas cores agora",
    lovedByUsers: "O que nossos usuários dizem",
    testimonialTitle: "O que dizem nossos usuários",
    testimonialSubtitle: "Descubra como a Palette Hunt tem ajudado outros a encontrar suas cores perfeitas.",
    testimonial1: "Nunca pensei que cores amarelas ficariam bem em mim, mas me ver nelas mudou minha opinião por completo.",
    testimonial2: "As fotos geradas por IA me ajudaram a visualizar como diferentes cores ficariam em mim.  incrível!",
    verifiedUser: "✓ Usuário Verificado",
    pricingTitle: "Escolha Seu Pacote Perfeito",
    pricingSubtitle: "Encontre o pacote de análise de cores perfeito para suas necessidades",
    basic: "Básico",
    basicPrice: "$19",
    basicDescription: "Perfeito para quem está começando sua jornada das cores",
    pro: "Pro",
    proPrice: "$39",
    proDescription: "Para quem quer se aprofundar em suas cores",
    custom: "Personalizado",
    customPrice: "Contate-nos",
    customDescription: "Para empresas e requisitos especiais",
    month: "/análise",
    features: "Recursos",
    basicFeatures: [
      "Relatório de Análise de Cores",
      "Paleta de Cores Sazonal",
      "Recomendações Básicas de Cores",
      "5 Fotos IA"
    ],
    proFeatures: [
      "Tudo do Básico",
      "Análise de Cores Detalhada",
      "Paleta de Cores Estendida",
      "20 Fotos IA",
      "Recomendações de Estilo",
      "Guia de Cores de Maquiagem"
    ],
    customFeatures: [
      "Tudo do Pro",
      "Requisitos Personalizados",
      "Suporte Prioritário",
      "Fotos IA Ilimitadas",
      "Acesso API",
      "Opção de Marca Branca"
    ],
    getFeatured: "Destaque-se",
    mostPopular: "Mais Popular",
    faqTitle: "Perguntas Frequentes",
    faqSubtitle: "Tudo que você precisa saber sobre análise de cores",
    faqs: [
      {
        question: "Como funciona o Palette Hunt?",
        answer: "Utilizamos os algoritmos de visão por IA mais avançados para analisar suas fotos e gerar paletas de cores personalizadas que complementam suas características únicas e preferências de estilo."
      },
      {
        question: "Quanto tempo leva para dar resultados?",
        answer: <>Normalmente você receberá seus resultados em 1 hora. Se os resultados demorarem mais de 2 horas, envie um email para <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "A qualidade dos resultados será boa?",
        answer: "Sim, podemos garantir que a qualidade dos resultados será a melhor que você pode obter no mercado. Mas você precisa nos fornecer fotos de boa qualidade para maximizar a qualidade dos resultados."
      },
      {
        question: "Preciso enviar muitas fotos?",
        answer: "Para resultados ideais, recomendamos enviar 8-10 fotos que mostrem diferentes ângulos, condições de iluminação e roupas. Isso determinará a qualidade dos resultados!"
      },
      {
        question: "Posso manter as imagens geradas?",
        answer: "Absolutamente! Você é proprietário de todas as imagens geradas pelo Palette Hunt. Tanto para o playground quanto para a análise de cores."
      },
      {
        question: "Posso usar o Palette Hunt em dispositivos móveis?",
        answer: "Sim, sem problema! O Palette Hunt funciona tanto em dispositivos móveis quanto em desktop. Sinta-se à vontade para usar o dispositivo com o qual você se sente mais confortável!"
      },
      {
        question: "Qual a precisão das recomendações de cores?",
        answer: "Nosso processo de recomendação de cores foi testado exaustivamente para garantir que nossos resultados sejam os melhores que você pode obter. Nossa taxa de satisfação é extremamente alta entre nossos usuários."
      },
      {
        question: "Que tipo de fotos vou receber?",
        answer: "Para cada paleta de cores que você receber, você terá 2 imagens suas nessa paleta de cores. Uma terá um fundo branco, como em um estúdio, e outra terá um fundo que combina com o tema de cores."
      },
      {
        question: "O que acontece se eu não gostar dos resultados?",
        answer: <>A qualidade é nossa principal prioridade. No momento, não podemos oferecer reembolsos completos, pois cada análise de cores significa um custo significativo por usuário. No entanto, temos certeza de que encontraremos uma solução para você. Estamos sempre disponíveis em <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline"><strong>hi@palettehunt.com</strong></a></>
      },
      {
        question: "Minhas fotos serão compartilhadas com alguém?",
        answer: "Não. Apenas usamos as fotos para treinar nossa IA, à qual ninguém mais tem acesso. Cada foto que você envia é excluída após uma semana."
      }
    ],
    haveQuestion: "Tem outra pergunta? Fale comigo no",
    or: "ou por",
    playgroundTitle: "Seu Estilo, Reinventado",
    playgroundDescription1: "Entre em nosso estúdio virtual de provas onde a imaginação não tem limites. Veja-se em quantos looks e cores quiser.",
    playgroundDescription2: "Crie imagens suas em estilos que você nunca pensou que poderia usar.",
    playgroundAlt: "Estúdio de Provas da Palette Hunt",
    carouselTitle: "Quer Entender Quais Cores Combinam Com Você?",
    carouselDescription: "Nossa análise de cores alimentada por IA fornece explicações detalhadas e mostra você transformado em suas cores perfeitas usando fotos avançadas geradas por IA.",
    carouselFeature1Title: "Análise Personalizada",
    carouselFeature1Description: "Obtenha uma análise de cores detalhada baseada em suas características únicas.",
    carouselFeature2Title: "Fotos Geradas por IA",
    carouselFeature2Description: "Veja-se transformado em suas cores perfeitas.",
    carouselFeature3Title: "Recomendações Especializadas",
    carouselFeature3Description: "Receba conselhos de estilo personalizados e combinações de cores.",
    imageGridTitle: "Veja-se em Suas Cores Perfeitas",
    imageGridDescription: "Não apenas leia sobre cores que poderiam ficar bem em você - veja-as em ação no seu modelo personalizado de IA. Nossa análise de cores completa mostra exatamente como sua paleta perfeita fica em você. Tudo que você precisa são algumas selfies.",
    aiPhotoLabel: "Foto IA",
    seasonTypes: {
      warmSpring: "Primavera Quente 🌸",
      warmAutumn: "Outono Quente 🍁",
      softSummer: "Verão Suave ☀️",
      deepAutumn: "Outono Profundo 🍂"
    },
    choosePlan: "Escolha Seu Plano",
    selectPlan: "Selecione um plano que atenda às suas necessidades e comece a descobrir sua paleta de cores perfeita.",
    basicPlan: "Plano Básico",
    proPlan: "Plano Pro",
    enterprise: "Empresas",
    custom: "Custom",
    oneOff: "Pagamento único",
    getStarted: "Começar",
    getPro: "Obter Pro",
    contactUs: "Fale Conosco",
    popular: "Popular",
    colorAnalysisTest: "Test de análise de cor",
    tailoredPalettes: "paletas de cores personalizadas",
    picsInColors: "fotos suas nessas cores",
    playgroundCredits: "créditos de probador virtual",
    goldSilverTest: "Teste de ouro ou prata",
    tailoredSolutions: "Soluções de análise de cor personalizadas",
    bulkPricing: "Opções de preços por volume",
    customisedFeatures: "Recursos personalizados"
  }
};

const TestimonialsSection = ({ isMobile, t }) => {
  return (
    <section className={`testimonials bg-white ${!isMobile ? 'mt-[-2rem] mb-4' : ''}`}>
      <div className="max-w-6xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">
            {t.testimonialTitle}
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            {t.testimonialSubtitle}
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-12">
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              {t.testimonial1}
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Laura</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                  {t.verifiedUser}
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
          <div className="bg-white p-8 rounded-lg shadow-lg border border-gray-100">
            <p className="text-xl text-gray-600 italic mb-6 relative z-10">
              {t.testimonial2}
            </p>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <h3 className="text-lg font-semibold text-gray-900 mr-2">Olympia</h3>
                <span className="inline-block border border-green-500 text-green-500 text-xs px-2 py-1 rounded-full">
                  {t.verifiedUser}
                </span>
              </div>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <svg key={i} className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                  </svg>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const FAQSection = ({ t }) => {
  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-12 gap-x-16 lg:gap-x-24">
          {/* Left Column - Title */}
          <div className="md:col-span-5">
            <h2 className="text-6xl font-bold text-gray-900 top-8">
              {t.faqTitle}
            </h2>
            <p className="mt-8 text-gray-600">
              {t.haveQuestion}{' '}
              <a href="mailto:hi@palettehunt.com" className="text-gray-900 hover:underline">email</a>.
            </p>
          </div>

          {/* Right Column - Questions */}
          <div className="md:col-span-7">
            {t.faqs.map((item, index) => (
              <details key={index} className="group border-t border-gray-200 [&>summary::-webkit-details-marker]:hidden [&>summary::marker]:hidden">
                <summary className="flex justify-between items-center py-5 cursor-pointer text-xl font-semibold text-gray-900">
                  {item.question}
                  <span className="ml-6 flex-shrink-0 text-2xl group-open:rotate-45 transition-transform duration-200">
                    +
                  </span>
                </summary>
                <div className="pb-5 text-gray-600">
                  {item.answer}
                </div>
              </details>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const PricingSection = ({ t, language }) => {
  const getLoginPath = () => {
    if (language === 'es') return '/es/login';
    if (language === 'pt') return '/pt/login';
    return '/login';
  };

  return (
    <section className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h3 className="text-4xl font-bold text-gray-900 mb-4">{t.choosePlan}</h3>
          <p className="text-xl text-gray-600">{t.selectPlan}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Basic Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.basicPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$15</span>
                <span className="text-gray-600 ml-2">{t.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>3</strong> {t.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>6</strong> {t.picsInColors}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>50</strong> {t.playgroundCredits}</span>
                </li>
              </ul>
            </div>
            <Link
              to={getLoginPath()}
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.getStarted}
            </Link>
          </div>

          {/* Pro Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border-2 border-gray-900 p-8">
            <div className="absolute top-0 right-6 transform -translate-y-1/2">
              <span className="bg-gray-900 text-white px-3 py-1 rounded-full text-sm font-medium">{t.popular}</span>
            </div>
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.proPlan}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">$21</span>
                <span className="text-gray-600 ml-2">{t.oneOff}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.colorAnalysisTest}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>5</strong> {t.tailoredPalettes}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>10</strong> {t.picsInColors}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600"><strong>150</strong> {t.playgroundCredits}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.goldSilverTest}</span>
                </li>
              </ul>
            </div>
            <Link
              to={getLoginPath()}
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.getPro}
            </Link>
          </div>

          {/* Enterprise Plan */}
          <div className="relative flex flex-col bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
            <div className="flex-grow">
              <h3 className="text-xl font-semibold text-gray-900 mb-4">{t.enterprise}</h3>
              <div className="flex items-baseline mb-4">
                <span className="text-5xl font-bold text-gray-900">{t.custom}</span>
              </div>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.tailoredSolutions}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.bulkPricing}</span>
                </li>
                <li className="flex items-center">
                  <svg className="w-5 h-5 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-gray-600">{t.customisedFeatures}</span>
                </li>
              </ul>
            </div>
            <a
              href="mailto:hi@palettehunt.com"
              className="w-full bg-gray-900 text-white rounded-full py-3 font-semibold hover:bg-gray-800 transition duration-300 text-center"
            >
              {t.contactUs}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

const ColorAnalysisLocation = () => {
  const { slug } = useParams();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1024);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const location = window.location.pathname;
  const [language, setLanguage] = useState(() => {
    if (location.startsWith('/es/')) return 'es';
    if (location.startsWith('/pt/')) return 'pt';
    return 'en';
  });
  const t = translations[language] || translations.en;
  const containerRef2 = useRef(null);
  const svgRef2 = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setIsTablet(window.innerWidth < 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Close language menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.language-menu')) {
        setLanguageMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Find location data with fallback to New York
  const locationData = locations.find(loc => loc.slug === slug) || locations.find(loc => loc.slug === 'new-york');
  
  // If no location is found at all (which shouldn't happen with the New York fallback)
  if (!locationData) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Location Not Found</h1>
          <p className="text-gray-600 mb-8">The requested location could not be found.</p>
          <Link
            to="/"
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800"
          >
            Return Home
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <header className="bg-white">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className={`flex justify-between items-center`}>
            <Link to="/" className="flex items-center">
              <img src="/logo.png" alt="Palette Hunt Logo" className="h-8 w-auto" />
              {!isMobile && <span className="ml-2 text-2xl font-bold text-gray-900">Palette Hunt</span>}
            </Link>
            <div className="flex items-center">
              {!isMobile ? (
                <>
                  <div className="flex items-center space-x-8 mr-10">
                    <Link to="/contact" className="text-gray-700 hover:text-gray-900 font-medium">
                      {t.contact}
                    </Link>
                    <div className="relative language-menu">
                      <button
                        onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
                        className="flex items-center text-gray-700 hover:text-gray-900 font-medium"
                      >
                        <Globe className="w-5 h-5 mr-1" />
                        <span className="uppercase mr-1">{language}</span>
                        <ChevronDown className="w-4 h-4" />
                      </button>
                      {languageMenuOpen && (
                        <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl z-20">
                          <Link
                            to="/"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            English
                          </Link>
                          <Link
                            to="/es"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Español
                          </Link>
                          <Link
                            to="/pt"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          >
                            Português
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                  <Link
                    to="/login"
                    className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.signIn}
                  </Link>
                </>
              ) : (
                <Link
                  to="/login"
                  className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                >
                  {t.getStarted}
                </Link>
              )}
            </div>
          </div>
        </nav>
      </header>

      <Helmet>
        <title>{`${t.title} ${locationData.name} | Palette Hunt`}</title>
        <meta name="description" content={`${t.description} ${locationData.name}. Transform your style, try on virtual looks, and get personalized recommendations. Unlock your true fashion potential today!`} />
      </Helmet>

      {/* Hero Section */}
      <section className="relative overflow-hidden">
        {/* Wrapper div that contains both Hero and LogoCarousel */}
        <div className="relative overflow-hidden">
          {/* Base white background with gradient blobs - only show on desktop */}
          {!isMobile && (
            <div className="absolute inset-0 bg-white">
              {/* Blue gradient blob */}
              <div className="absolute -left-1/4 top-1/4 h-[600px] w-[600px] animate-pulse rounded-full bg-blue-600/30 blur-[120px]" />
              
              {/* White gradient */}
              <div 
                className="absolute right-0 top-0 bottom-0 w-1/2"
                style={{
                  background: 'linear-gradient(to right, transparent, white)'
                }}
              />
            </div>
          )}

          {/* Hero Content */}
          <div className={`relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${isMobile ? 'pt-2' : 'pt-16'} pb-16`}>
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 mb-8 md:mb-0">
                <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-6 text-gray-900">
                  {t.title} {locationData.name}
                </h1>
                <p className="text-xl text-gray-600 mb-8">
                  <span className="block mb-2">{t.heroSubtitle.split('.')[0].replace('{location}', locationData.name)}.</span>
                  {t.heroSubtitle.split('.')[1].trim().replace('{location}', locationData.name)}.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Link
                    to={language === 'en' ? '/' : `/${language}`}
                    className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
                  >
                    {t.getStarted}
                  </Link>
                  <a
                    href="https://apps.apple.com/app/palette-hunt-color-analysis/id6737066020"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center justify-center px-8 py-3 border border-gray-900 text-base font-bold rounded-full text-gray-900 bg-white hover:bg-gray-50"
                  >
                    <svg className="h-5 w-5 mr-2" viewBox="0 0 384 512">
                      <path fill="currentColor" d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
                    </svg>
                    {language === 'en' ? 'Download App' :
                     language === 'es' ? 'Descargar App' :
                     'Baixar App'}
                  </a>
                </div>
              </div>
              <div className="md:w-1/2">
                <img
                  src={locationData.heroImage || (language === 'es' ? "/hero_es.png" : language === 'pt' ? "/hero_pt.png" : "/hero.png")}
                  alt={`Color Analysis in ${locationData.name}`}
                  className="w-full h-auto max-w-lg rounded-lg"
                />
              </div>
            </div>
          </div>

          {/* Logo Carousel */}
          <LogoCarousel />
        </div>
      </section>

      


      {/* Location Overview Section */}
      <section className="bg-white py-24">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-gray-900 mb-16 text-center">
            {t.colorAnalysisIn.replace('{location}', locationData.name)}
          </h2>

          {/* Location Overview and Services */}
          <div className="mb-24">
            <div className="max-w-3xl mx-auto">
              <div className="flex items-start space-x-6 mb-8">
                <div className="bg-gray-50 p-4 rounded-xl">
                  <FaMapMarkerAlt className="text-3xl text-gray-900" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-6">{t.locationOverview}</h3>
                  <p className="text-gray-600 leading-relaxed text-lg">{locationData.description}</p>
                </div>
              </div>

              <div className="flex items-start space-x-6">
                <div className="bg-gray-50 p-4 rounded-xl">
                  <FaPalette className="text-3xl text-gray-900" />
                </div>
                <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-6">{t.colorAnalysisServices}</h3>
                  <p className="text-gray-600 leading-relaxed text-lg">
                    {t.colorAnalysisServicesDesc.replace('{location}', locationData.name)}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Pricing and Services Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
            {/* Pricing Information */}
            <div className="bg-gray-50 rounded-2xl p-12">
              <div className="flex items-start space-x-6 mb-8">
                <FaDollarSign className="text-4xl text-gray-900" />
                <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-4">{t.pricingInformation}</h3>
                  <p className="text-gray-600 text-lg mb-6">
                    {t.averagePriceRange.replace('{location}', locationData.name)}
                  </p>
                  <div className="bg-white rounded-xl py-4 px-8 inline-block">
                    <p className="text-3xl font-bold text-gray-900">{locationData.pricingRange}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Popular Services */}
            <div className="bg-gray-50 rounded-2xl p-12">
              <div className="flex items-start space-x-6">
                <FaBuilding className="text-4xl text-gray-900" />
                <div>
                  <h3 className="text-2xl font-bold text-gray-900 mb-6">{t.popularColorAnalysisServices}</h3>
                  <ul className="space-y-5">
                    {locationData.businesses.map((business, index) => (
                      <li key={index} className="flex items-center text-lg">
                        <div className="w-2 h-2 bg-gray-900 rounded-full mr-4"></div>
                        <div>
                          <span className="text-gray-900 font-bold">
                            {typeof business === 'string' ? business : business.name}
                          </span>
                          {typeof business !== 'string' && business.description && (
                            <p className="text-gray-600 mt-1">
                              {business.description}
                            </p>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Image Carousel Section */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-6">
              {t.carouselTitle}
            </h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              {t.carouselDescription}
            </p>
          </div>
          <div className="relative max-w-4xl mx-auto mb-16">
            <div className="absolute left-0 top-0 bottom-0 w-32 bg-gradient-to-r from-white to-transparent z-10"></div>
            <div className="absolute right-0 top-0 bottom-0 w-32 bg-gradient-to-l from-white to-transparent z-10"></div>
            <div className="overflow-hidden">
              <div className="flex animate-carousel">
                {[...Array(2)].map((_, setIndex) => (
                  <div key={setIndex} className="flex space-x-8 flex-none">
                    {[...Array(9)].map((_, index) => (
                      <div key={`${setIndex}-${index}`} className="flex-none">
                        <img
                          src={`/carousel${index + 1}.jpeg`}
                          alt={`Color Analysis Example ${index + 1}`}
                          className="w-auto h-auto max-h-[300px] rounded-xl shadow-lg"
                        />
                      </div>
                    ))}
                    <div className="w-8 flex-none"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
            <div className="text-center">
              <div className="flex justify-center mb-6">
                <div className="w-12 h-12 rounded-full bg-gray-900 flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                {t.carouselFeature1Title}
              </h3>
              <p className="text-gray-600">
                {t.carouselFeature1Description}
              </p>
            </div>
            <div className="text-center">
              <div className="flex justify-center mb-6">
                <div className="w-12 h-12 rounded-full bg-gray-900 flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                {t.carouselFeature2Title}
              </h3>
              <p className="text-gray-600">
                {t.carouselFeature2Description}
              </p>
            </div>
            <div className="text-center">
              <div className="flex justify-center mb-6">
                <div className="w-12 h-12 rounded-full bg-gray-900 flex items-center justify-center">
                  <svg className="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-3">
                {t.carouselFeature3Title}
              </h3>
              <p className="text-gray-600">
                {t.carouselFeature3Description}
              </p>
            </div>
          </div>
        </div>
        <style jsx>{`
          .animate-carousel {
            animation: scroll 90s linear infinite;
          }
          @keyframes scroll {
            0% { transform: translateX(0); }
            100% { transform: translateX(-50%); }
          }
          .animate-carousel:hover {
            animation-play-state: paused;
          }
        `}</style>
      </section>

         
      {/* Image Grid Section */}
      <section className="pt-24 pb-12 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center text-gray-900 mb-12">
            {t.imageGridTitle}
          </h2>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto text-center mb-8">
            {t.imageGridDescription}
          </p>
          <div ref={containerRef2} className={`relative w-full ${isTablet ? 'md:h-[400px]' : 'md:h-[600px]'} mb-16 md:mb-0`}>
            <svg ref={svgRef2} className="absolute top-0 left-0 w-full h-full text-secondary pointer-events-none z-10" />
            <div className="flex flex-col md:flex-row justify-between items-center">
              {/* Left Column */}
              <div className="w-full md:w-[48%] mb-8 md:mb-0">
                <div className="relative">
                  {/* Top row of small images */}
                  <div className="flex space-x-3 mb-2">
                    <ImageWithFallback 
                      src="/grid1.png" 
                      alt="Grid 1" 
                      className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                    />
                  </div>
                  {/* Large images */}
                  <div className="flex space-x-4">
                    <div className="relative w-1/2">
                      <ImageWithFallback 
                        src="/laura-large-2.png" 
                        alt="Laurita" 
                        className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                      />
                      <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">{t.aiPhotoLabel}</span>
                      <div className="mt-4 text-center">
                        <p className="text-gray-900 text-lg mb-3"><strong>{t.seasonTypes.warmSpring}</strong></p>
                        <div className="flex justify-center space-x-1">
                          <div className="w-6 h-6 rounded-full bg-[#FF7F50]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#FA8072]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#228B22]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#2E8B57]"></div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-1/2">
                      <ImageWithFallback 
                        src="/orange-2.jpg" 
                        alt="Sunny Yellow" 
                        className="w-full h-48 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-105" 
                      />
                      <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">{t.aiPhotoLabel}</span>
                      <div className="mt-4 text-center">
                        <p className="text-gray-900 text-lg mb-3"><strong>{t.seasonTypes.warmAutumn}</strong></p>
                        <div className="flex justify-center space-x-1">
                          <div className="w-6 h-6 rounded-full bg-[#E57373]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#DDAA33]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#556B2F]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isMobile && <div className="mb-8"></div>}
              {/* Right Column */}
              <div className="w-full md:w-[48%] md:w-1/2">
                <div className="relative">
                  {/* Top row of small images */}
                  <div className="flex space-x-3 mb-2 justify-end">
                    <ImageWithFallback 
                      src="/grid2.png" 
                      alt="Grid 2" 
                      className="w-56 h-12 md:w-72 md:h-16 object-contain transition-transform duration-300 hover:scale-105" 
                    />
                  </div>
                  {/* Large images */}
                  <div className="flex space-x-4">
                    <div className="relative w-1/2">
                      <ImageWithFallback 
                        src="/olympia_large_21.png" 
                        alt="Olympia AI 2" 
                        className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                      />
                      <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">{t.aiPhotoLabel}</span>
                      <div className="mt-4 text-center">
                        <p className="text-gray-900 text-lg mb-3"><strong>{t.seasonTypes.softSummer}</strong></p>
                        <div className="flex justify-center space-x-1">
                          <div className="w-6 h-6 rounded-full bg-[#8B8589]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#778899]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#B8A6B3]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#9AA3A8]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#A7A69D]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#8C8C8C]"></div>
                        </div>
                      </div>
                    </div>
                    <div className="relative w-1/2">
                      <ImageWithFallback 
                        src="/olympia_ai_3.png" 
                        alt="Olympia Transform 1" 
                        className="w-full h-56 md:h-72 object-cover shadow-lg transition-transform duration-300 hover:scale-102" 
                      />
                      <span className="absolute top-2 left-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full shadow-md">{t.aiPhotoLabel}</span>
                      <div className="mt-4 text-center">
                        <p className="text-gray-900 text-lg mb-3"><strong>{t.seasonTypes.deepAutumn}</strong></p>
                        <div className="flex justify-center space-x-1">
                          <div className="w-6 h-6 rounded-full bg-[#8B4513]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#DAA520]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#CD853F]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#A0522D]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#D2691E]"></div>
                          <div className="w-6 h-6 rounded-full bg-[#B8860B]"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Playground Section */}
      <section className={`${isMobile ? 'py-24' : 'py-4 mb-48'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className={`flex flex-col ${isMobile ? 'items-center' : 'md:flex-row md:items-center md:space-x-12'}`}>
            <div className={`${isMobile ? 'w-full text-center' : 'md:w-1/2 text-left'} mb-12 md:mb-0`}>
              <h2 className={`${isMobile ? 'text-3xl' : 'text-4xl'} font-bold mb-6 text-gray-900`}>{t.playgroundTitle}</h2>
              <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-4`}>
                {t.playgroundDescription1}
              </p>
              <p className={`${isMobile ? 'text-lg' : 'text-xl'} text-gray-600 mb-6`}>
                {t.playgroundDescription2}
              </p>
            </div>
            <div className={`${isMobile ? 'w-full' : 'md:w-1/2'}`}>
              <img 
                src={language === 'es' ? "/playground_es.png" : language === 'pt' ? "/playground_pt.png" : "/playground.png"}
                alt={t.playgroundAlt} 
                className={`${isMobile ? 'w-3/4' : 'w-4/5'} mx-auto object-cover`} 
              />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <TestimonialsSection isMobile={isMobile} t={t} />

      {/* Pricing Section */}
      <PricingSection t={t} language={language} />

      {/* FAQ Section */}
      <FAQSection t={t} />

      {/* Call to Action Section */}
      <section className="bg-white py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-4xl font-bold text-gray-900 mb-8">
              {t.experiencePaletteHunt.replace('{location}', locationData.name)}
            </h2>
            <Link
              to={language === 'en' ? '/login' : `/${language}/login`}
              className="inline-flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold rounded-full text-white bg-gray-900 hover:bg-gray-800"
            >
              {t.tryPaletteHuntNow}
            </Link>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ColorAnalysisLocation;