import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Helmet } from 'react-helmet';
import { Capacitor } from '@capacitor/core';
import { App as CapacitorApp } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import BottomTabNavigator from './components/BottomTabNavigator';
import LandingPage from './components/LandingPage';
import LandingPageIOS from './components/LandingPageIOS';
import OnboardingSlides from './components/OnboardingSlides'; // Import the new component
import LandingPageES from './components/LandingPageES';
import LandingPagePT from './components/LandingPagePT';
import Login from './components/Login';
import ImageUpload from './components/ImageUpload';
import ColorAnalysisTest from './components/ColorAnalysisTest';
import SharedColorAnalysis from './components/SharedColorAnalysis';
import WaitingScreen from './components/WaitingScreen';
import PaymentScreen from './components/PaymentScreen';
import Playground from './components/Playground';
import Credits from './components/Credits';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import AboutPaletteHunt from './components/AboutPaletteHunt';
import ErrorPage from './components/ErrorPage';
import ColorAnalysisLocation from './components/ColorAnalysisLocation';
import locations from './data/locations';
import Contact from './components/Contact';
import Settings from './components/Settings';
import ColorAnalysisQuiz from './components/ColorAnalysisQuiz';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = Capacitor.isNativePlatform();
  const [hasSeenOnboarding, setHasSeenOnboarding] = useState(false);

  useEffect(() => {
    const initApp = async () => {
      if (isMobile) {
        try {
          // Keep the splash screen visible while we perform initialization
          await SplashScreen.show({
            showDuration: 2000,
            autoHide: false,
          });

          // Perform any initialization tasks here
          // For example, check authentication, load initial data, etc.
          await new Promise(resolve => setTimeout(resolve, 1000)); // Simulating some loading time

          const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
          setHasSeenOnboarding(!!hasSeenOnboarding);

          // Hide the splash screen
          await SplashScreen.hide();
        } catch (error) {
          console.error('Error during app initialization:', error);
        }
      }
      setIsLoading(false);
    };

    initApp();
  }, [isMobile]);

  if (isLoading) {
    return null;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Nerko+One&display=swap" rel="stylesheet" />
        </Helmet>
        <Routes>
          {isMobile && !hasSeenOnboarding && (
            <Route path="/" element={<OnboardingSlides />} />
          )}
          <Route path="/" element={isMobile ? <LandingPageIOS /> : <LandingPage />} />
          <Route path="/es" element={<LandingPageES />} />
          <Route path="/pt" element={<LandingPagePT />} />
          <Route path="/login" element={<Login />} />
          <Route path="/es/login" element={<Login language="es" />} />
          <Route path="/pt/login" element={<Login language="pt" />} />
          <Route path="/upload" element={<ImageUpload />} />
          <Route path="/color-analysis" element={<ColorAnalysisTest />} />
          <Route path="/color-analysis/:slug" element={<ColorAnalysisLocation />} />
          <Route path="/es/colorimetria/:slug" element={<ColorAnalysisLocation />} />
          <Route path="/pt/coloracao-pessoal/:slug" element={<ColorAnalysisLocation />} />
          <Route path="/shared/:publicId" element={<SharedColorAnalysis />} />
          <Route path="/waiting" element={<WaitingScreen />} />
          <Route path="/payment" element={<PaymentScreen language="en" />} />
          <Route path="/es/payment" element={<PaymentScreen language="es" />} />
          <Route path="/pt/payment" element={<PaymentScreen language="pt" />} />
          <Route path="/playground" element={<Playground />} />
          <Route path="/credits" element={<Credits />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/es/blog" element={<Blog />} />
          <Route path="/pt/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost language="en" />} />
          <Route path="/es/blog/:slug" element={<BlogPost language="es" />} />
          <Route path="/pt/blog/:slug" element={<BlogPost language="pt" />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/es/privacy-policy" element={<PrivacyPolicy language="es" />} />
          <Route path="/pt/privacy-policy" element={<PrivacyPolicy language="pt" />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/es/terms-and-conditions" element={<TermsAndConditions language="es" />} />
          <Route path="/pt/terms-and-conditions" element={<TermsAndConditions language="pt" />} />
          <Route path="/about" element={<AboutPaletteHunt />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<ErrorPage />} />
          {/* New long-tail keyword routes */}
          <Route path="/ai-color-analysis" element={<Navigate to="/" replace />} />
          <Route path="/color-analysis-app" element={<Navigate to="/" replace />} />
          <Route path="/color-analysis-near-me" element={<Navigate to="/" replace />} />
          <Route path="/best-online-color-analysis" element={<Navigate to="/" replace />} />
          <Route path="/virtual-color-consultation" element={<Navigate to="/" replace />} />
          <Route path="/ai-powered-color-analysis" element={<Navigate to="/" replace />} />
          <Route path="/personal-color-palette-generator" element={<Navigate to="/" replace />} />
          <Route path="/find-my-best-colors-online" element={<Navigate to="/" replace />} />
          <Route path="/professional-color-analysis-online" element={<Navigate to="/" replace />} />
          <Route path="/color-me-beautiful-alternative" element={<Navigate to="/" replace />} />
          <Route path="/digital-color-draping" element={<Navigate to="/" replace />} />
          <Route path="/ai-stylist-color-analysis" element={<Navigate to="/" replace />} />
          <Route path="/virtual-color-analysis-consultation" element={<Navigate to="/" replace />} />
          <Route path="/online-personal-color-analysis" element={<Navigate to="/" replace />} />
          <Route path="/color-analysis-app" element={<Navigate to="/" replace />} />
          <Route path="/ai-fashion-color-advisor" element={<Navigate to="/" replace />} />
          <Route path="/do-color-analysis-for-photo-online​" element={<Navigate to="/" replace />} />

          {/* Spanish routes */}
          <Route path="/es/analisis-de-color-cerca-de-mi" element={<Navigate to="/es" replace />} />
          <Route path="/es/mejor-analisis-de-color-en-linea" element={<Navigate to="/es" replace />} />
          <Route path="/es/consulta-virtual-de-color" element={<Navigate to="/es" replace />} />
          <Route path="/es/analisis-de-color-con-ia" element={<Navigate to="/es" replace />} />
          <Route path="/es/generador-de-paleta-de-colores-personal" element={<Navigate to="/es" replace />} />
          <Route path="/es/encuentra-tus-mejores-colores-en-linea" element={<Navigate to="/es" replace />} />
          <Route path="/es/herramienta-de-analisis-de-color-estacional" element={<Navigate to="/es" replace />} />
          <Route path="/es/analisis-de-color-profesional-en-linea" element={<Navigate to="/es" replace />} />
          <Route path="/es/alternativa-a-color-me-beautiful" element={<Navigate to="/es" replace />} />
          <Route path="/es/drapeado-de-color-digital" element={<Navigate to="/es" replace />} />
          <Route path="/es/estilista-ia-analisis-de-color" element={<Navigate to="/es" replace />} />
          <Route path="/es/consulta-virtual-analisis-de-color" element={<Navigate to="/es" replace />} />
          <Route path="/es/analisis-de-color-personal-en-linea" element={<Navigate to="/es" replace />} />
          <Route path="/es/aplicacion-de-analisis-de-color" element={<Navigate to="/es" replace />} />
          <Route path="/es/asesor-de-color-de-moda-ia" element={<Navigate to="/es" replace />} />

          {/* Portuguese routes */}
          <Route path="/pt/analise-de-cores-perto-de-mim" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/melhor-analise-de-cores-online" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/consulta-virtual-de-cores" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/analise-de-cores-com-ia" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/gerador-de-paleta-de-cores-pessoal" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/encontre-suas-melhores-cores-online" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/ferramenta-de-analise-de-cores-sazonal" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/analise-de-cores-profissional-online" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/alternativa-ao-color-me-beautiful" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/drapeado-de-cores-digital" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/estilista-ia-analise-de-cores" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/consulta-virtual-analise-de-cores" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/analise-de-cores-pessoal-online" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/aplicativo-de-analise-de-cores" element={<Navigate to="/pt" replace />} />
          <Route path="/pt/consultor-de-cores-de-moda-ia" element={<Navigate to="/pt" replace />} />
          
          <Route path="/settings" element={<Settings />} />
          <Route path="/seasonal-color-analysis-tool" element={<ColorAnalysisQuiz />} />
          <Route path="/seasonal-color-analysis-quiz" element={<ColorAnalysisQuiz />} />
          <Route path="/color-analysis-quiz" element={<ColorAnalysisQuiz />} />
          <Route path="/test-de-colorimetria" element={<ColorAnalysisQuiz language="es" />} />
          <Route path="/colorimetria-test" element={<ColorAnalysisQuiz language="es" />} />
          <Route path="/analise-de-cores" element={<ColorAnalysisQuiz language="pt" />} />
        </Routes>
        {isMobile && hasSeenOnboarding && <BottomTabNavigator />}
        <Analytics />
        <SpeedInsights />
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
